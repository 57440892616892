import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpParams, HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import { FullCalendarComponent } from '@fullcalendar/angular';
import momentPlugin from '@fullcalendar/moment';
import { toMoment, toDuration } from '@fullcalendar/moment';
// import { RowHeightCache } from '@swimlane/ngx-datatable/release/utils';
import { ActivatedRoute } from '@angular/router';

const api = environment.API;
const MaDateAfficheePipe = {
  transform: (value) => {
    if (!value) {
      return '';
    }
    // console.log('date avant conversion', value);
    if (value !== +value) {
      const year = value.substring(0, 4);
      const month = value.substring(5, 7);
      const day = value.substring(8, 10);
      // console.log({ year, month, day });
      value = new Date(year, month - 1, day);
    }
    return value ? new DatePipe('fr-FR').transform(value) : '';
  }
};
const TelPortableRegExp = '^0[6-7][0-9]{8}$';
const telRegExp = '^0[0-9]{9}$';
const courrielRegex = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$)';

@Component({
  selector: 'app-famille',
  templateUrl: './famille.component.html',
  styleUrls: ['./famille.component.scss']
})
export class FamilleComponent implements OnInit, OnChanges {
  public resultatForDebug; // Variable uniquement utilisée pour afficher un debug à l'écran
  public etape: number = 1;
  public indice_eleve_selectionne: number = -1;

  public nbResponsablePrincipal = 1;
  public nbReinscriptions = 99; // Pour ne pas afficher le message d'erreur lorsqu'on arrive et que le calcul n'a pas encore été réalisé

  calendarPlugins = [dayGridPlugin, listPlugin, bootstrapPlugin, momentPlugin]; // important!
  public listeRendezVousDisponibles = [];
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  public options = {
    locale: 'fr',
    views: {
      listDay: { buttonText: 'jour' },
      listWeek: { buttonText: 'semaine' },
      listMonth: { buttonText: 'mois' }
    },
    header: {
      left: 'title',
      center: 'prev,next today',
      right: '  listDay,listWeek,listMonth dayGridDay,dayGridWeek,dayGridMonth' // listDay,listWeek,listMonth
    },
    buttonText: {
      today: 'Aujourd\'hui',
      month: 'mois',
      week: 'semaine',
      day: 'jour'
    },
    titleFormat: 'D MMMM YYYY'
  }
  public libelleRendezVous = '';
  private rdvSelectionne;

  @ViewChild('editEleveComponent') editEleveComponent: any;

  // Listes déroulantes
  // public listeNiveauScolaire = [];
  // public listeStatutEleve = [];
  public listeParente = [];
  public listeCivilite = [];
  public listeCours = []; // Cours de l'année n (année courante). Ne pas confondre avec les cours de l'année n+1
  public listeTypeDisciplines = [];
  public listeDisciplinesSelectionnee = [];
  protected listeDisciplines = [];
  public listeTypeRendezVous = [];

  private subListes: any;
  // Colonnes actions des tableaux
  @ViewChild('listActionTemplateResponsable', { static: true }) listActionTemplateResponsable;
  @ViewChild('listActionTemplateEleve', { static: true }) listActionTemplateEleve;
  @ViewChild('listActionTemplateCours', { static: true }) listActionTemplateCours;

  // Templates des colonnes affichage nom prenom

  @ViewChild('inscription_eleve_template', { static: true }) inscription_eleve_template;
  @ViewChild('inscription_professeur_template', { static: true }) inscription_professeur_template: TemplateRef<any>;
  @ViewChild('editNouveauCours') editNouveauCours;
  @ViewChild('messageInfo') messageInfo;
  public messageInfoUtilisateur = "";

  // Colonnes des tableaux (initialisées dans ngOnInit pour que les templates soient déjà chargés)
  responsables_columns = [];
  eleves_columns = [];
  inscriptions_columns = [];
  facture_columns = [];

  @Input() famille: {
    nom: string,
    id: number,
    eleves: Object[],
    responsables: Object[],
    inscriptions: Object[],
    adresse: Object,
    commentaire: string;
  };
  public factures: Object[];

  @Output() saved: EventEmitter<any> = new EventEmitter<any>();

  // Donnees modifiees ou creees
  familleModifiee: {
    adresse?: Object,
    responsables?: Object[],
    eleves?: Object[],
    inscriptions?: {
      isReinscriptionDemandee,
      isReinscriptionImpossible,
    }[],
  } = {};

  public formAdresse: FormGroup;
  public formResponsable: FormGroup;
  public messageErreurResponsable = '';
  private selectedResponsable: Object;

  public selectedEleve: Object;
  public estEleveCourantFISansFM: boolean = false;
  public formChoixCours: FormGroup;
  public selectedInscription: Object;
  public formCommentaire: FormGroup;
  public formInscription: FormGroup;

  public formRendezVous: FormGroup;
  public isDebug: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public ngxSmartModalService: NgxSmartModalService,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.isDebug = this.route.snapshot.queryParamMap.get('debug') == 'true';
    console.log({ famille: this.famille,
      isDebug: this.isDebug,
      params: this.route.snapshot.paramMap,
      queryParamMap: this.route.snapshot.queryParamMap
    });
    localStorage.setItem('title', this.famille.nom + ' (' + this.famille.id + ')');
    this.loadDataListes();
    this.responsables_columns = [
      // { prop: 'lien_parente_libelle', name: 'Lien parenté', cellTemplate: '', minWidth: 25 },
      { prop: 'civilite_libelle', name: 'Civilité', cellTemplate: '', minWidth: 25, flexGrow: 1 },
      { prop: 'nom', name: 'Nom', cellTemplate: '', minWidth: 50, flexGrow: 2 },
      { prop: 'prenom', name: 'Prénom', flexGrow: 2 },
      { prop: 'telephoneDomicile', name: 'Tél domicile', flexGrow: 2 },
      { prop: 'telephoneMobile', name: 'Tél mobile', flexGrow: 2 },
      { prop: 'telephoneProfessionnel', name: 'Tél Pro', flexGrow: 2 },
      { prop: 'email', name: 'Courriel', flexGrow: 4 },
      { prop: 'respPrincipal', name: 'Principal', pipe: { transform: (value) => { return value == 1 ? 'X' : '' } }, flexGrow: 1 },
      { prop: 'actions', name: 'Actions', cellTemplate: this.listActionTemplateResponsable, minWidth: 50, flexGrow: 2 },
    ];
    this.eleves_columns = [
      { prop: 'civilite_libelle', name: 'Civilité', cellTemplate: '', flexGrow: 1 },
      { prop: 'nom', name: 'Nom', flexGrow: 2 },
      { prop: 'prenom', name: 'Prénom', flexGrow: 2 },
      { prop: 'sexe', name: 'Sexe', flexGrow: 1 },
      { prop: 'dateNaissStr', name: 'Date naissance', pipe: MaDateAfficheePipe, flexGrow: 2 },
      { prop: 'email', name: 'Courriel', flexGrow: 4 },
      { prop: 'telephonePortable', name: 'Tél mobile', flexGrow: 2 },
      { prop: 'actions', name: 'Actions', cellTemplate: this.listActionTemplateEleve, flexGrow: 2 }, // minWidth: 50,
    ];

    this.inscriptions_columns = [
      //{ name: 'Eleve', minWidth: 30, cellTemplate: this.inscription_eleve_template },
      { prop: 'discipline_libelle', name: 'Discipline', cellTemplate: '', minWidth: 30 },
      { name: 'Professeur', cellTemplate: this.inscription_professeur_template, minWidth: 30 },
      // { prop: 'jourLibelle', name: 'Jour', cellTemplate: '', minWidth: 10 },
      // { prop: 'duree', name: 'Durée (min)', cellTemplate: '', minWidth: 10 },
      // { prop: 'cycle_libelle', name: 'Cycle', cellTemplate: '', minWidth: 10 },
      // { prop: 'niveau_libelle', name: 'Niveau', cellTemplate: '', minWidth: 10 },
      { prop: 'next_cycle_libelle', name: 'Cycle futur', cellTemplate: '', minWidth: 10 },
      { prop: 'next_niveau_libelle', name: 'Niveau futur', cellTemplate: '', minWidth: 10 },
      { prop: 'actions', name: 'Pré-inscription', cellTemplate: this.listActionTemplateCours, minWidth: 50, }
    ];

    this.formCommentaire = this.formBuilder.group({
      commentaire: [this.famille && this.famille.commentaire ? this.famille.commentaire : null],
    });

    this.facture_columns = [
      { prop: 'libelle', name: 'Libellé', cellTemplate: '', minWidth: 30 },
      { prop: 'montantFinal', name: 'Montant', cellTemplate: '', minWidth: 30 },
    ];

    this.initEtape(false);
  }
  initEtape(avecPassageEtapeSuivante = true) {
    console.log('initEtape debut', { etape: this.etape, indiceEleve: this.indice_eleve_selectionne, avecPassageEtapeSuivante, eleves: this.famille.eleves, inscriptions: this.famille.inscriptions, });
    switch (this.etape) {
      case 1: // Adresse
        if (avecPassageEtapeSuivante) {
          this.etape++;
        }
        this.onEditAdresse();
        break;
      case 2: // Responsables
        if (avecPassageEtapeSuivante) {
          this.etape++;
          // Vu que l'on passe à l'étape élève, il faut initialiser l'indice de l'élève à traiter
          this.indice_eleve_selectionne = 0;
          this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
          this.verifieCoherenceInscriptions();
        }
        break;
      case 3: // Elèves avec inscriptions
        if (avecPassageEtapeSuivante) {
          if (this.famille.eleves && this.famille.eleves.length > 0) {
            if (this.indice_eleve_selectionne + 1 < this.famille.eleves.length) {
              // Passage à l'élève suivant
              this.indice_eleve_selectionne++;
              this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
            } else {// Passage etape suivante
              this.indice_eleve_selectionne = -1;
              this.selectedEleve = null;
              this.etape++;
            }
          } else {
            this.indice_eleve_selectionne = -1;
            this.selectedEleve = null;
            this.etape++;
          }
        }
        this.verifieCoherenceInscriptions();
        break;
      case 4: // Récapitulatif élèves avec inscriptions (mais sans estimation du cout)
        if (avecPassageEtapeSuivante) {
          this.etape++;
        }
        this.isAuMoinsUneInscription(); // pour calculer le nombre d'inscriptions
        break;
      case 5: // Affichage cout (sans le récap)
        if (avecPassageEtapeSuivante) {
          this.etape++;
          this.chargeDonneesRendezVous();
        }
        break;
      case 6: // Prise de rendez-vous
        if (avecPassageEtapeSuivante) {
          this.etape++;
        }
        break;
      case 7: // Confirmation RDV
        localStorage.setItem('title', '');
        break;
    }
    console.log('initEtape fin', { etape: this.etape, indiceEleve: this.indice_eleve_selectionne, eleves: this.famille.eleves });
  }
  goEtape(etape) {
    // console.log('goEtape', etape);
    this.etape = etape;
    if (etape == 3 && this.famille.eleves && this.famille.eleves.length > 0) {
      this.indice_eleve_selectionne = 0
      this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
      this.verifieCoherenceInscriptions();
    }
  }
  goEtapePrecedente() {
    console.log('goEtapePrecedente debut', { etape: this.etape, indiceEleve: this.indice_eleve_selectionne });
    switch (this.etape) {
      case 3: // Eleves
        if (this.indice_eleve_selectionne == 0) {
          // On est sur le 1er eleve => passage étape précédents
          this.indice_eleve_selectionne = -1;
          this.selectedEleve = null;
          this.etape--;
        } else {
          // Passage sur l'eleve precedent
          this.indice_eleve_selectionne--;
          this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
        }
        break;
      case 4: //
        this.etape--;
        this.indice_eleve_selectionne = 0;
        this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
        break;
      default:
        this.etape--;
        break;
    }
    console.log('goEtapePrecedente fin', { etape: this.etape, indiceEleve: this.indice_eleve_selectionne });
  }
  loadDataListes() {
    if (this.subListes) {
      this.subListes.unsubscribe();
    }
    const optionsParente = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_parente']))
    };
    const optionsCivilite = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_civilite']))
    };

    const optionsNiveauScolaire = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_niveau_scolaire']))
    };
    const optionsStatutEleve = {
      params: new HttpParams().set('service', 'ServiceReference')
        .set('method', 'getReferenceObjects')
        .set('params', JSON.stringify(['ref_statut_eleve']))
    };
    forkJoin([
        this.http.get(api, optionsParente),
        this.http.get(api, optionsCivilite)
    ]
      //this.http.get(api, optionsNiveauScolaire),
      //this.http.get(api, optionsStatutEleve),

    )
      .subscribe(data => {
        //const [dataNiveauScolaire, dataStatutEleve, dataParente, dataCivilite] = data;
        const [dataParente, dataCivilite] = data;
        // console.log(data, this.listeNiveauScolaire);
        const dataVide = { id: null, libelle: '' };

        /*if (!Array.isArray(dataNiveauScolaire)) {
          let dataNiveauSColaire=[];
        }
        this.listeNiveauScolaire = <Object[]>dataNiveauScolaire;
        if (!Array.isArray(this.listeNiveauScolaire)) {
          this.listeNiveauScolaire = [];
        }
        this.listeNiveauScolaire = [... this.listeNiveauScolaire]
        this.listeNiveauScolaire.unshift(dataVide);*/

        if (!Array.isArray(dataParente)) {
          let dataParente = [];
        }
        this.listeParente = <Object[]>dataParente;
        if (!Array.isArray(this.listeParente)) {
          this.listeParente = [];
        }
        this.listeParente = [... this.listeParente]
        this.listeParente.unshift(dataVide);

        if (!Array.isArray(dataCivilite)) {
          let dataCivilite = [];
        }
        this.listeCivilite = <Object[]>dataCivilite;
        if (!Array.isArray(this.listeCivilite)) {
          this.listeCivilite = [];
        }
        this.listeCivilite = [... this.listeCivilite]
        // this.listeCivilite.unshift(dataVide);

        /*if (!Array.isArray(dataStatutEleve)) {
          let dataStatutEleve=[];
        }
        this.listeStatutEleve = <Object[]>dataStatutEleve;
        if (!Array.isArray(this.listeStatutEleve)) {
          this.listeStatutEleve = [];
        }
        this.listeStatutEleve = [... this.listeStatutEleve]
        // this.listeStatutEleve.unshift(dataVide);*/
      },
        err => console.log(err));
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(changes, this.famille);
    if (changes.famille) {
      console.log('###### famille changed', this.famille.eleves);
    }
    //this.initEtape();

  }

  // ###################### ADRESSE #############################################################
  adresse() {
    return this.famille && this.famille.adresse ? this.famille.adresse : {};
  }

  public isError(form: FormGroup, chp: string): boolean {
    return form.get(chp) && form.get(chp).invalid && form.get(chp).touched;
  }

  onEditAdresse() {
    // console.log('onEditAdresse'), this.adresse(), this.adresse()['adresse_1'];
    this.formAdresse = this.formBuilder.group({
      id: [
        this.adresse()['id'],
      ],
      adresse1: [
        this.adresse()['adresse1'],
        Validators.required,
      ],
      adresse2: [
        this.adresse()['adresse2'],
      ],
      codePostal: [
        this.adresse()['codePostal'],
        Validators.required,
      ],
      ville: [
        this.adresse()['ville'],
        Validators.required,
      ],
    });
    // On ne passe plus par la popup
    // this.ngxSmartModalService.getModal('editAdresse').open();
  }
  saveAdresse() {
    // console.log('saveAdresse');
    if (this.adresse()['adresse1'] != this.formAdresse.value.adresse1
      || this.adresse()['adresse2'] != this.formAdresse.value.adresse2
      || this.adresse()['codePostal'] != this.formAdresse.value.codePostal
      || this.adresse()['ville'] != this.formAdresse.value.ville) {
      console.log('adresse modifiee');
      this.famille.adresse = this.formAdresse.value;
      this.familleModifiee.adresse = this.formAdresse.value;
      this.saveDonneesModifiees();
    } else {
      this.initEtape();
    }
  }



  // ###################### RESPONSABLE #############################################################
  onEditResponsable(rowIndex, row) {
    // console.log('onEditResponsable', rowIndex, row);
    this.selectedResponsable = row;
    this.createFormResponsable(row);
    this.ngxSmartModalService.getModal('editResponsable').open();
  }
  onDeleteResponsable(rowIndex, row) {
    // console.log('onDeleteResponsable', row);
    if (row.id == null) {
      // objet n'existe pas encore en BDD => suppression de l'élève (pas d'inscriptions car non propose par l'interface)
      console.log('onDeleteResponsable', row, this.famille, this.familleModifiee);
      // suppression de la liste this.familleModifiee.responsables
      this.familleModifiee.responsables = this.familleModifiee.responsables.filter(obj => { return obj['idCreation'] != row['idCreation'] });
      // Suppression de la liste this.famille.responsables
      this.famille.responsables = this.famille.responsables.filter(obj => { return obj['idCreation'] != row['idCreation'] });
    } else {
      // mettre isDeleted à 1
      row.isDeleted = 1;
      // Mise à jour liste des éléments modifiés
      const indiceResponsableDejaModifie = this.familleModifiee.responsables ? this.familleModifiee.responsables.findIndex((value) => value['id'] == row.id) : -1;
      if (indiceResponsableDejaModifie < 0) {
        if (!this.familleModifiee.responsables) {
          this.familleModifiee.responsables = [];
        }
        this.familleModifiee.responsables.push(row);
      } else {
        this.familleModifiee.responsables[indiceResponsableDejaModifie] = row;
      }
      // console.log('proceder a la suppression en BDD : pour le moment on n accepte la suppression uniquement pour les objets non encore enregistres');
    }
  }
  addResponsable() {
    const rowResponsable = {
      id: null,
      isCreation: true,
      nom: this.famille['nom'],
      idCreation: this.nextIdCreation(this.famille.responsables),
      isCreationFamille: 1,
    };
    console.log('addResponsable', rowResponsable);
    this.selectedResponsable = rowResponsable;
    this.createFormResponsable(rowResponsable);
    this.ngxSmartModalService.getModal('editResponsable').open();
  }
  private createFormResponsable(row: Object) {
    this.formResponsable = this.formBuilder.group({
      id: [
        row['id'],
      ],
      idCreation: [
        row['idCreation'],
      ],
      isCreation: [
        row['isCreation'],
      ],
      isCreationFamille: [
        row['isCreationFamille'],
      ],
      civilite: [
        row['civilite'],
      ],
      civilite_libelle: [
        row['civilite_libelle'],
      ],
      email: [
        row['email'], Validators.pattern(courrielRegex),
      ],
      familleId: [
        row['familleId'],
      ],
      lienParente: [
        row['lienParente'],
      ],
      lien_parente_libelle: [
        row['lien_parente_libelle'],
      ],
      nom: [
        row['nom'],
        Validators.required,
      ],
      prenom: [
        row['prenom'],
      ],
      principal: [
        row['respPrincipal'] == 1,
      ],
      telephoneDomicile: [
        row['telephoneDomicile'], Validators.pattern(telRegExp),
      ],
      telephoneMobile: [
        row['telephoneMobile'], Validators.pattern(TelPortableRegExp),
      ],
      telephoneProfessionnel: [
        row['telephoneProfessionnel'], Validators.pattern(telRegExp),
      ],
    });
  }
  saveResponsable() {
    // Obliger la saisie d'un téléphone ou du courriel
    this.messageErreurResponsable = '';
    if (!this.formResponsable.value.email
      && !this.formResponsable.value.telephoneDomicile
      && !this.formResponsable.value.telephoneMobile
      && !this.formResponsable.value.telephoneProfessionnel) {
      this.messageErreurResponsable = "Veuillez saisir au moins un numéro de téléphone ou l'adresse email";
      return;
    }
    console.log('saveResponsable', this.formResponsable.value, this.famille.responsables);
    const isCreation = this.selectedResponsable['isCreation'] === true;
    this.formResponsable.patchValue({
      nom: this.formResponsable.value.nom.toUpperCase(),
      prenom: (this.formResponsable.value.prenom && this.formResponsable.value.prenom.length > 0) ?
        (this.formResponsable.value.prenom.charAt(0).toUpperCase() + this.formResponsable.value.prenom.substring(1)) : ''
    });

    if (isCreation
      || this.selectedResponsable['email'] != this.formResponsable.value.email
      || this.selectedResponsable['telephoneDomicile'] != this.formResponsable.value.telephoneDomicile
      || this.selectedResponsable['telephoneMobile'] != this.formResponsable.value.telephoneMobile
      || this.selectedResponsable['telephoneProfessionnel'] != this.formResponsable.value.telephoneProfessionnel
      || this.selectedResponsable['lienParente'] != this.formResponsable.value.lienParente
      || this.selectedResponsable['civilite'] != this.formResponsable.value.civilite
      || this.selectedResponsable['pricipal'] != this.formResponsable.value.principal
    ) {
      // console.log('responsable modifie', this.formResponsable.value);
      if (this.selectedResponsable['pricipal'] != this.formResponsable.value.principal) {
        if (this.formResponsable.value.principal) {
          // Principal a ete coche => decocher sur les autres responsables -> sera à faire côté serveur ?
        }
      }
      this.formResponsable.value.respPrincipal = (this.formResponsable.value.principal ? '1' : '0');
      if (this.selectedResponsable['lienParente'] != this.formResponsable.value.lienParente) {
        // lien parente modifie
        const parente = this.listeParente.find(elt => elt.id == this.formResponsable.value.lienParente);
        // console.log('lien parente modifie', this.formResponsable.value.lienParente, parente);
        if (parente) {
          this.formResponsable.patchValue({ lien_parente_libelle: parente.libelle });
        }
      }
      if (this.selectedResponsable['civilite'] != this.formResponsable.value.civilite) {
        // civilite modifie
        const civilite = this.listeCivilite.find(elt => elt.id == this.formResponsable.value.civilite);
        if (civilite) {
          this.formResponsable.patchValue({ civilite_libelle: civilite.libelle });
        }
      }
      // Mise à jour liste des éléments modifiés
      // Si c'est une création (isCreation==true), il ne faut pas regarder l'id mais idCreation
      const indiceResponsableDejaModifie = this.familleModifiee.responsables ? this.familleModifiee.responsables.findIndex((value) => this.formResponsable.value.isCreation == true ? value['idCreation'] == this.formResponsable.value.idCreation : value['id'] == this.formResponsable.value.id) : -1;
      if (indiceResponsableDejaModifie < 0) {
        if (!this.familleModifiee.responsables) {
          this.familleModifiee.responsables = [];
        }
        this.familleModifiee.responsables.push(this.formResponsable.value);
      } else {
        this.familleModifiee.responsables[indiceResponsableDejaModifie] = this.formResponsable.value;
      }
      // Mise à jour dans les données d'origine pour l'affichege direct
      const indiceResponsable = this.famille.responsables ? this.famille.responsables.findIndex((value) => this.formResponsable.value.isCreation == true ? value['idCreation'] == this.formResponsable.value.idCreation : value['id'] == this.formResponsable.value.id) : -1;
      if (indiceResponsable < 0) {
        // Normalement impossible car on a ouvert le resppnsable à partir de cette liste
        // Normalement impossible sauf si création car on a ouvert l'eleve à partir de cette liste
        if (isCreation) {
          this.famille.responsables.push(this.formResponsable.value);
          this.famille.responsables = [...this.famille.responsables];
        }
      } else {
        this.famille.responsables[indiceResponsable] = this.formResponsable.value;
        this.famille.responsables = [...this.famille.responsables];
      }
    }
    this.ngxSmartModalService.close('editResponsable');
    this.controleUniciteResponsablePrincipal();
    console.log('saveResponsable (fin)', this.famille.responsables);
  }
  controleUniciteResponsablePrincipal() {
    let nbResponsables = 0;
    this.famille.responsables.forEach(resp => {
      if (resp['respPrincipal'] == 1 && resp['isDeleted'] != 1) {
        nbResponsables++;
      }
    });
    this.nbResponsablePrincipal = nbResponsables;
    if (this.nbResponsablePrincipal == 1) {
      console.log('enregistrement responsables', this.nbResponsablePrincipal);
      return true;
    } else {
      console.log('problème sur le nombre de responsable principal : ', this.nbResponsablePrincipal);
      return false;
    }
  }
  validerResponsables() {
    // enregistrer les données uniquement s'il y a un et un seul responsable principal
    if (this.controleUniciteResponsablePrincipal()) {
      this.saveDonneesModifiees();
    } else {
      this.initEtape();
    }

  }

  // ###################### ELEVE #############################################################
  existeEleveCreeParFamille() {
    // console.log('existeEleveCreeParFamille');
    if (this.famille && this.famille.eleves) {
      const reducer = (bool, eleve) => bool || +eleve.isCreationFamille==1;
      const res = this.famille.eleves.reduce(reducer, false);
      // console.log({ res, eleves: this.famille.eleves, etape: this.etape});
      return res;
    } else {
      return false;
    }
    //isCreationFamille
  }
  libelleElevesCreesParFamille() {
    // console.log('libelleElevesCreesParFamille');
    if (this.famille && this.famille.eleves) {
      const reducer = (libelle, eleve) => libelle + (+eleve.isCreationFamille == 1 ? eleve.prenom+' ' : '');
      const res = this.famille.eleves.reduce(reducer, '');
      // console.log({ res, eleves: this.famille.eleves, etape: this.etape });
      return res;
    } else {
      return '';
    }
  }
  eleveSaved(dataEleve) {
    console.log('eleveSaved', dataEleve);
    if (dataEleve) {
      // Mise à jour liste des éléments modifiés
      const indiceEleveDejaModifie = this.findIndiceMemeObjet(this.familleModifiee.eleves, dataEleve);
      console.log({ indiceEleveDejaModifie });
      /*this.familleModifiee.eleves ?
        this.familleModifiee.eleves.findIndex((value) =>
        value['isCreation'] ? value['idCreation'] == this.formEleve.value.idCreation : value['id'] == this.formEleve.value.id)
        : -1;*/
      if (indiceEleveDejaModifie < 0) {
        if (!this.familleModifiee.eleves) {
          this.familleModifiee.eleves = [];
        }
        this.familleModifiee.eleves.push(dataEleve);
      } else {
        this.familleModifiee.eleves[indiceEleveDejaModifie] = dataEleve;
      }
      // Mise à jour dans les données d'origine pour l'affichege direct
      const indiceEleve = this.findIndiceMemeObjet(this.famille.eleves, dataEleve);
      console.log({ indiceEleve })
      // this.famille.eleves ? this.famille.eleves.findIndex((value) => value['id'] == this.formEleve.value.id) : -1;
      if (indiceEleve < 0) {
        // Normalement impossible sauf si création car on a ouvert l'eleve à partir de cette liste
        const isCreation = this.selectedEleve['isCreation'] === true;
        if (isCreation) {
          this.famille.eleves.push(dataEleve);
          this.famille.eleves = [...this.famille.eleves];
          this.saveDonneesModifiees(false,false); // enregistrement données pour pouvoir ajouter des inscriptions sur l'élève sans aller jusqu'à la synthèse
        }
      } else {
        this.famille.eleves[indiceEleve] = dataEleve;
        this.famille.eleves = [...this.famille.eleves];
      }
    }
    this.fermeEditEleve();
  }
  addEleve() {
    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'initNewEleve')
        // .set('params', JSON.stringify([localStorage.getItem('id_famille'), localStorage.getItem('anneeScolaire'), this.familleModifiee, avecValidation])) // JSON.stringify({ token: token }) //id_famille
    };
    this.http.get(api, options).subscribe(data=>{
      console.log(data);
      data['id'] = null;
      data['isCreation'] = true;
      data['nom'] = this.famille['nom'];
      data['idCreation'] = this.nextIdCreation(this.famille.eleves);
      data['isCreationFamille'] = 1;
      this.selectedEleve = data;
      this.ngxSmartModalService.getModal('editEleve').open();
    });
    /*const rowEleve = {
      id: null,
      isCreation: true,
      nom: this.famille['nom'],
      idCreation: this.nextIdCreation(this.famille.eleves),
      isCreationFamille: 1,
    };
    console.log('addEleve', rowEleve);
    this.selectedEleve = rowEleve;
    //this.createFormEleve(rowEleve);
    this.ngxSmartModalService.getModal('editEleve').open();*/
  }

  onEditEleve(rowIndex, row) {
    console.log('onEditEleve', { rowIndex, row, eleves: this.famille.eleves });
    this.selectedEleve = row;
    //this.createFormEleve(row);
    //console.log(this.formEleve);
    this.ngxSmartModalService.getModal('editEleve').open();
    console.log('onEditEleve opened', { rowIndex, row, eleves: this.famille.eleves });
  }
  fermeEditEleve() {
    this.ngxSmartModalService.getModal('editEleve').close();
    console.log({ indiceEleveSelectionne: this.indice_eleve_selectionne });
    if (this.indice_eleve_selectionne >= 0) {
      this.selectedEleve = this.famille.eleves[this.indice_eleve_selectionne];
    } else {
      this.selectedEleve = null;
    }
    console.log(this.selectedEleve);
  }
  onDeleteEleve(rowIndex, row) {
    console.log('onDeleteEleve', row);
    if (row.id == null) {
      // objet n'existe pas encore en BDD => suppression de l'élève (pas d'inscriptions car non propose par l'interface)
      console.log('onDeleteEleve', row, this.famille, this.familleModifiee);

      // suppression de la liste this.familleModifiee.eleves
      // const indexEleveDansFamilleModifieeEleves = this.familleModifiee.eleves.findIndex(eleve=>{return eleve['idCreation']=row['idCreation']});
      this.familleModifiee.eleves = this.familleModifiee.eleves.filter(eleve => { return eleve['idCreation'] != row['idCreation'] });
      // Suppression de la liste this.famille.eleves
      // const indexEleveDansFamilleEleves = this.famille.eleves.findIndex(eleve=>{return eleve['idCreation']=row['idCreation']});
      this.famille.eleves = this.famille.eleves.filter(eleve => { return eleve['idCreation'] != row['idCreation'] });
    } else {
      // mettre isDeleted à 1
      row.isDeleted = 1;
      // Mise à jour liste des éléments modifiés
      const indiceEleveDejaModifie = this.familleModifiee.eleves ? this.familleModifiee.eleves.findIndex((value) => value['id'] == row.id) : -1;
      if (indiceEleveDejaModifie < 0) {
        if (!this.familleModifiee.eleves) {
          this.familleModifiee.eleves = [];
        }
        this.familleModifiee.eleves.push(row);
      } else {
        this.familleModifiee.eleves[indiceEleveDejaModifie] = row;
      }
      //console.log('proceder a la suppression en BDD : pour le moment on n accepte la suppression uniquement pour les objets non encore enregistres');
    }
  }
  /*  saveEleve() {
      console.log('saveEleve', { eleve: this.selectedEleve, form: this.formEleve });
      const isCreation = this.selectedEleve['isCreation'] === true;
      if (isCreation
        || this.selectedEleve['email'] != this.formEleve.value.email
        || this.selectedEleve['telephonePortable'] != this.formEleve.value.telephonePortable
        || this.selectedEleve['statut'] != this.formEleve.value.statut
        || this.selectedEleve['niveauScolaire'] != this.formEleve.value.niveauScolaire
        || this.selectedEleve['accordMedia'] != this.formEleve.value.accordMedia
        || this.selectedEleve['bool_01'] != this.formEleve.value.bool_01

      ) {
        if (this.formEleve.value['dateNaissStr']) {
          // Conversion date en string yyyy-mm-dd (format envoyé par le serveur)
          const dateValue = this.formEleve.value['dateNaissStr'];
          this.formEleve.patchValue({
            dateNaissStr: new DatePipe('fr-FR').transform(dateValue, 'yyyy-MM-dd'),
          })
          console.log('date convertie pour enregistrement', this.formEleve.value['dateNaissStr']);
        }
        // Mise à jour liste des éléments modifiés
        const indiceEleveDejaModifie = this.findIndiceMemeObjet(this.familleModifiee.eleves, this.formEleve.value);
        console.log({ indiceEleveDejaModifie });

        if (indiceEleveDejaModifie < 0) {
          if (!this.familleModifiee.eleves) {
            this.familleModifiee.eleves = [];
          }
          this.familleModifiee.eleves.push(this.formEleve.value);
        } else {
          this.familleModifiee.eleves[indiceEleveDejaModifie] = this.formEleve.value;
        }
        // Mise à jour dans les données d'origine pour l'affichege direct
        const indiceEleve = this.findIndiceMemeObjet(this.famille.eleves, this.formEleve.value);
        console.log({ indiceEleve })
        // this.famille.eleves ? this.famille.eleves.findIndex((value) => value['id'] == this.formEleve.value.id) : -1;
        if (indiceEleve < 0) {
          // Normalement impossible sauf si création car on a ouvert l'eleve à partir de cette liste
          if (isCreation) {
            this.famille.eleves.push(this.formEleve.value);
            this.famille.eleves = [...this.famille.eleves];
          }
        } else {
          this.famille.eleves[indiceEleve] = this.formEleve.value;
          this.famille.eleves = [...this.famille.eleves];
        }
      }
      this.ngxSmartModalService.close('editEleve');
    }*/

  validerEleve() {
    console.log('validerEleve (debut) : on a cliqué sur Elève suivant', { eleves: this.famille.eleves, indice_eleve_selectionne: this.indice_eleve_selectionne });
    this.editEleveComponent.saveEleve();
    console.log({ selectedEleve: this.selectedEleve, eleves: this.famille.eleves });
    this.saveDonneesModifiees();
    console.log('validerEleve (fin)', { eleves: this.famille.eleves });
    /*if (this.indice_eleve_selectionne < this.famille.eleves.length) {
      console.log('validerEleve passage eleve suivant', { indiceEleve: this.indice_eleve_selectionne, nbEleves: this.famille.eleves.length });
      //this.indice_eleve_selectionne++; // c'est dejà fait dan initxxx
    } else {
      console.log('validerEleve passage etape suivante');
      this.etape++;
    }*/
    //this.initEtape();
  }

  validerEleves() {
    if (this.isAuMoinsUneInscription()) {
      this.saveDonneesModifiees();
    }
  }
  // ###################### Commun #############################################################
  /**
   * Gestion des idCreation pour tous les objets créés (et gérés en liste)
   * @param tableau
   */
  private nextIdCreation(tableau: Array<Object>): number {
    let nextIdCreation: number = 1;
    tableau.forEach(element => {
      if (element['isCreation']) {
        if (element['idCreation'] >= nextIdCreation) {
          nextIdCreation = element['idCreation'] + 1;
        }
      }
    });
    return nextIdCreation;
  }
  private findIndiceMemeObjet(tableau: Array<Object>, objetRecherche: Object) {
    return tableau ?
      tableau.findIndex((value) =>
        value['isCreation'] ? value['idCreation'] == objetRecherche['idCreation'] : value['id'] == objetRecherche['id'])
      : -1;
  }

  public saveDonneesModifiees(avecValidation = false, avecPassageEtapeSuivante = true) {
    console.log('saveDonneesModifiees', this.familleModifiee);
    // Envoyer this.familleModifiee à l'enregistrement sur le serveur
    if (this.famille.commentaire != this.formCommentaire.value.commentaire) {
      this.familleModifiee['commentaire'] = this.formCommentaire.value.commentaire;
    }
    this.familleModifiee['etape'] = this.etape; // Permet au serveur de savoir s'il faut générer une facture (étape 5)
    console.log(this.familleModifiee);

    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'saveModifFamille')
        .set('params', JSON.stringify([localStorage.getItem('id_famille'), localStorage.getItem('anneeScolaire'), this.familleModifiee, avecValidation])) // JSON.stringify({ token: token }) //id_famille
    };
    this.http.get(api, options).subscribe(data => {
      console.log({ data, etape: this.etape });
      //si data['inscription'] n'est pas vide, voir s'il y a un élément avec inscription['retour']==-2 => inscription['idOrigine'] doit indiquer inscription impossible car futur niveau ne peut être déterminé
      if (data['inscriptions']) {
        const data_retour_inscriptions = <any[]>data['inscriptions'];
        if (data_retour_inscriptions.length > 0) {
          console.log('retour inscription à traiter', data_retour_inscriptions);
          data_retour_inscriptions.forEach(inscription_retour => {
            if (inscription_retour['retour'] == -2) {
              console.log('une reinscription n a pas pu etre realisee car impossible de determiner le futur niveau');
              const idInscriptionOrigine = inscription_retour['idOrigine'];
              this.famille.inscriptions.forEach(inscription => {
                if (inscription['idInscription'] == idInscriptionOrigine) {
                  inscription['isReinscriptionImpossible'] = true;
                  inscription['message'] = "Niveau de l'élève inconnu";
                  // Ne sert à rien car on recharge => il faut détecter le problème en amont
                }
              });
            } else {
              //id_inscription_an_suiv: "6122", isDeleted: true
              const id_inscription_an_suiv = inscription_retour['id_inscription_an_suiv'];
              if (inscription_retour['isDeleted'] == true) {
                // Recherche de l'inscription dans la liste des inacriptions
                const inscriptionSupprimee = this.famille.inscriptions.findIndex((inscription) => {
                  return inscription['id_inscription_an_suiv'] == id_inscription_an_suiv
                });
                if (+inscriptionSupprimee['is_creation_famille'] == 1) {
                  // L'inscription supprimee avait ete créée à partir d'une nouvelle discipline => suppression de l'inscription des listes
                  // supprimer l'inscription des 2 listes : uniquement si isCreationFamille, sinon retirer uniquement la partie réinscription
                  // suppression de la liste this.familleModifiee.inscriptions
                  this.familleModifiee.inscriptions = this.familleModifiee.inscriptions.filter(inscription => { return inscription['id_inscription_an_suiv'] != id_inscription_an_suiv });
                  // Suppression de la liste this.famille.inscriptions
                  this.famille.inscriptions = this.famille.inscriptions.filter(inscription => { return inscription['id_inscription_an_suiv'] != id_inscription_an_suiv });
                } else {
                  // L'inscription existait déjà l'année précédente => on la propose à nouveau pour une réinscription possible
                  // supprimer les attribute isDeleted et id_inscription_an_suiv
                  this.familleModifiee.inscriptions.map(inscription => {
                    if (inscription['id_inscription_an_suiv'] == id_inscription_an_suiv) {
                      inscription['isDeleted'] = null;
                      inscription['id_inscription_an_suiv'] = null;
                      inscription['is_creation_famille_an_suivante'] = null;
                    }
                  });
                  this.famille.inscriptions.map(inscription => {
                    if (inscription['id_inscription_an_suiv'] == id_inscription_an_suiv) {
                      inscription['isDeleted'] = null;
                      inscription['id_inscription_an_suiv'] = null;
                      inscription['is_creation_famille_an_suivante'] = null;
                    }
                  });
                }
              }
            }
          });
        }
      }
      if (data['responsables']) {
        const data_retour_responsables = <any[]>data['responsables'];
        if (data_retour_responsables.length > 0) {
          console.log('retour responsable à traiter', data_retour_responsables);
          data_retour_responsables.forEach(responsable => {
            const idCreation = responsable['idCreationTemporaire'];
            const idBDD = responsable['id'];
            // Mettre à jour l'id et mettre isCreation à false
            const indiceResponsable = this.famille.responsables ? this.famille.responsables.findIndex((value) => value['idCreation'] == idCreation) : -1;
            this.famille.responsables[indiceResponsable]['id'] = idBDD;
            this.famille.responsables[indiceResponsable]['isCreation'] = false;
          })
        }
      }
      if (data['eleves']) {
        const data_retour_eleves = <any[]>data['eleves'];
        if (data_retour_eleves.length > 0) {
          console.log('retour eleves à traiter', data_retour_eleves);
          data_retour_eleves.forEach(eleve => {
            const idCreation = eleve['idCreationTemporaire'];
            const idBDD = eleve['id'];
            // Mettre à jour l'id et mettre isCreation à false
            const indiceEleve = this.famille.eleves ? this.famille.eleves.findIndex((value) => value['idCreation'] == idCreation) : -1;
            this.famille.eleves[indiceEleve]['id'] = idBDD;
            this.famille.eleves[indiceEleve]['isCreation'] = false;
          })
        }
      }
      if (data['factures']) {
        const data_retour_factures = <any[]>data['factures'];
        if (data_retour_factures.length > 0) {
          this.factures = data_retour_factures;
        }
      } else {
        // Ce n'est plus nécessaire d'invalider la session car on arrive sur la dernière page avec message de confirmation du rendez-vous sans possibilité de rvenir en arrière.
        // this.saved.emit(avecValidation);
      }
      this.familleModifiee = {}; // réinitialisation pour ne pas recréer ce qui l'a déj été
      this.resultatForDebug = data;
      console.log('saveDonneesModifiees termine OK', { etape: this.etape, indice_eleve: this.indice_eleve_selectionne, eleves: this.famille.eleves });
      if (avecValidation) {
        localStorage.setItem('title', '');
      }
      this.initEtape(avecPassageEtapeSuivante);
    },
      err => {
        console.log(err);
      }
    );
  }
  //this.etape++;

  // ###################### INSCRIPTION #############################################################
  public typeDisciplineChanged(idTypeDiscipline) {
    console.log('typeDisciplineChanged', idTypeDiscipline);
    if (idTypeDiscipline) {
      this.listeDisciplinesSelectionnee = this.listeDisciplines.filter(discipline => { return discipline.type_discipline_id == idTypeDiscipline });
      console.log(this.listeDisciplinesSelectionnee);
    }
  }
  public disciplineChanged(idDiscipline) {
    // Réinitialisation des valeurs dépendant de la discipline
    this.formInscription.controls['messageUtilisateur'].patchValue(''); // Réinitialisation du message utilisateur
    this.formInscription.controls['isReinscriptionImpossible'].patchValue(false);
    this.formInscription.controls['isValidationSimple'].patchValue(false);
    this.formInscription.controls['idCours'].patchValue(null);
    this.listeCours = [];

    console.log('disciplineChanged', idDiscipline, this.formInscription);
    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'choixReinscription')
        .set('params', JSON.stringify([null, idDiscipline, localStorage.getItem('anneeScolaire')]))
    };
    this.http.get(api, options).subscribe(data => {
      console.log(data);
      if (data['valid_simple'] == true) {
        // simple validation de linscription : c'est le serveur qui se chargera d'effectuer le reste
        console.log('Validation simple demandée', this.formInscription);
        this.formInscription.controls['isValidationSimple'].patchValue(true);
        this.formInscription.controls['idCours'].clearValidators();
        this.formInscription.controls['idCours'].updateValueAndValidity();
        //this.famille.inscriptions[indexDansFamilleInscriptions]['isReinscriptionDemandee'] = true;
        //this.ajouteReInscription(row);
      } else if (data['listeCours'] && data['listeCours'].length > 0) {
        this.formInscription.controls['isValidationSimple'].patchValue(false);
        this.formInscription.controls['idCours'].setValidators([Validators.required]);
        this.formInscription.controls['idCours'].updateValueAndValidity();
        // Afficher la liste des cours proposés
        this.listeCours = data['listeCours'];
        console.log('Choix de cours à faire', data['listeCours']);
        // this.createFormChoixCours(-1);
        let idParDefaut = null;
        if (this.listeCours.length == 1) {
          idParDefaut = this.listeCours[0].id;
        }
        this.formInscription.controls['idCours'].patchValue(idParDefaut, Validators.required);



        // TODO afficher la liste de choix de cours
        //this.createFormChoixCours(indexDansFamilleInscriptions);
        //this.ngxSmartModalService.getModal('editChoixCours').open();

      } else {
        this.formInscription.controls['isValidationSimple'].patchValue(false);
        this.formInscription.controls['isReinscriptionImpossible'].patchValue(true);

        // réinscription impossible car pas de cours disponible. Proposer à la famille d'ajouter un commentaire pour exprimer ses souhaits
        const discipline = this.listeDisciplines.find(discipline => discipline.id == this.formInscription.value.idDiscipline);
        const libelleDiscipline = discipline['libelle'];
        const messageUtilisateur = 'Inscription de l\'élève ' + this.formInscription.value.libelleEleve + ' impossible car aucun cours n\'est disponible pour la discipline ' + libelleDiscipline;
        this.famille.commentaire = ((this.famille.commentaire && this.famille.commentaire.length > 0) ? this.famille.commentaire + '\n' : '') + messageUtilisateur;
        console.log('Aucun cours proposé : réinscription impossible');
        this.formInscription.controls['messageUtilisateur'].patchValue(messageUtilisateur);
        // this.famille.inscriptions[indexDansFamilleInscriptions]['isReinscriptionImpossible'] = true;
        // this.ajouteReInscription(row);
      }
      this.resultatForDebug = data;

    },
      err => console.log(err));
  }
  public onConfirmInscription(rowIndex, row) {
    // /!\ ne pas utiliser rowIndex car le tableau est utilisé avec un filtre => index correspond à un tableau partiel
    // console.log({ rowIndex, row });
    this.selectedInscription = row;
    const inscriptionId = row.idInscription;
    const disciplineId = row.disciplineId;
    // console.log('rechercheCoursPossibles', { inscriptionId, disciplineId });
    //choixReinscription($inscriptionId, $disciplineId, $anneeScolaire)
    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'choixReinscription')
        .set('params', JSON.stringify([inscriptionId, disciplineId, localStorage.getItem('anneeScolaire')]))
    };
    const indexDansFamilleInscriptions = this.famille.inscriptions.findIndex((inscription) => {
      return inscription['idInscription'] == row.idInscription || (inscription['idEleve'] == row.idEleve && inscription['disciplineId'] == row.disciplineId)
    });
    // console.log({ indexDansFamilleInscriptions });
    this.http.get(api, options).subscribe(data => {
      console.log({data, row});
      if (data['valid_simple'] == true) {
        // simple validation de la réinscription : c'est le serveur qui se chargera d'effectuer le reste
        // console.log('Validation simple demandée');
        this.famille.inscriptions[indexDansFamilleInscriptions]['isReinscriptionDemandee'] = true;
        this.ajouteReInscription(row);
      } else if (data['listeCours'] && data['listeCours'].length > 0) {
        // Afficher la liste des cours proposés
        this.listeCours = data['listeCours'];
        // console.log('Choix de cours à faire', data['listeCours']);
        this.createFormChoixCours(indexDansFamilleInscriptions);
        this.ngxSmartModalService.getModal('editChoixCours').open();

      } else {
        // réinscription impossible car pas de cours disponible. Proposer à la famille d'ajouter un commentaire pour exprimer ses souhaits
        // console.log('Aucun cours proposé : réinscription impossible');
        this.famille.inscriptions[indexDansFamilleInscriptions]['isReinscriptionImpossible'] = true;
        this.ajouteReInscription(row);
      }
      this.resultatForDebug = data;

    },
      err => console.log(err));
  }


  private ajouteReInscription(rowInscription: Object) {
    console.log('ajouteReInscription', rowInscription, this.famille.inscriptions);
    // Recherche si l'inscription est dejà dans la liste des modifications
    let indexInscription = -1;
    if (!this.familleModifiee.inscriptions) {
      this.familleModifiee.inscriptions = [];
    }
    if (this.familleModifiee.inscriptions && rowInscription['idInscription']) {
      indexInscription = this.familleModifiee.inscriptions.findIndex((ins) => {
        return ins['idOrigine'] == rowInscription['idInscription'];
      });
    }
    console.log(indexInscription, rowInscription);
    let reInscription = {
      isReinscriptionDemandee: rowInscription['isReinscriptionDemandee'],
      isReinscriptionImpossible: rowInscription['isReinscriptionImpossible'],
    };
    if (indexInscription >= 0) {
      reInscription = this.familleModifiee.inscriptions[indexInscription];
    } else {
      reInscription['idOrigine'] = rowInscription['idInscription'];
    }
    if (rowInscription['idCoursCible']) {
      reInscription['idCoursCible'] = rowInscription['idCoursCible'];
    }
    if (rowInscription['libelleCours']) {
      reInscription['libelleCours'] = rowInscription['libelleCours'];
    }
    // pour les nouvelles inscriptions : idDiscipline et idEleve
    if (rowInscription['idDiscipline']) {
      reInscription['idDiscipline'] = rowInscription['idDiscipline'];
    }
    if (rowInscription['idEleve']) {
      reInscription['idEleve'] = rowInscription['idEleve'];
    }

    // reInscription['isReinscriptionDemandee'] = rowInscription['isReinscriptionDemandee'];
    // reInscription['isReinscriptionImpossible'] = rowInscription['isReinscriptionImpossible'];
    if (indexInscription >= 0) {
      this.familleModifiee.inscriptions[indexInscription] = reInscription;
    } else {
      this.familleModifiee.inscriptions.push(reInscription);
    }
    this.famille.inscriptions = [... this.famille.inscriptions]; // Pour rafraichir l'affichage du tableau
    this.verifieCoherenceInscriptions();
    console.log('fin ajouteReInscription', { reinscriptions: this.famille.inscriptions, changemente: this.familleModifiee });
  }

  public isDemandeReinscriptionPossible(row) {
    return this.etape < 5
      && row.ok != '-2' // erreur technique lors du traitement de la réinscription
      && !row.isReinscriptionDemandee
      && !row.isReinscriptionImpossible
      && ((!row.libelleCours
        && row.id_inscription_an_suiv == null)
        || row.isDeleted == 1)
  }
  private createFormChoixCours(indexInscription?) {
    console.log('createFormChoixCours');
    let idParDefaut = null;
    if (this.listeCours.length > 0) {
      idParDefaut = this.listeCours[0].id;
    }
    this.formChoixCours = this.formBuilder.group({
      id: [idParDefaut,
        Validators.required,],
      indexInscription: [indexInscription],
    });
  }

  public libelleCours(row): string {
    // console.log('libelleCours', row);
    const profLibelle = (row.professeur_nom ? row.professeur_nom + ' ' : '')
      + (row.professeur_prenom ? row.professeur_prenom : '');
    const horaireDebut = row.horaire_debut ? (Math.trunc(row.horaire_debut / 60).toString().padStart(2, '0') + ':' + (row.horaire_debut % 60).toString().padStart(2, '0')) : '';
    const estListeAttente = row.isListeAttente == 1;
    const cycle = row.cycle_libelle ? row.cycle_libelle : '';
    const niveau = row.niveau_libelle ? row.niveau_libelle : '';
    const cycleNiveau = cycle + ((cycle && niveau) ? '/':'') + niveau;
    return (row.discipline_libelle ? row.discipline_libelle + ' - ' : '')
      + (profLibelle.length > 0 ? profLibelle + ' - ' : '')
      + (row.jour_libelle ? row.jour_libelle + ' - ' : '')
      + (horaireDebut ? horaireDebut : '')
      + (cycleNiveau.length > 0 ? ' - ' + cycleNiveau : '')
      + (estListeAttente ? ' (liste d\'attente)' : '');
  }
  public getCours(idCours) {
    return this.listeCours.find(cours => cours.id == idCours);
  }
  public isListeAttente(idCours) {
    const cours = this.getCours(idCours);
    if (cours) {
      return cours.isListeAttente == 1;
    } else {
      return false;
    }
  }
  private CalculateAge(dateNaissance, dateCalcul): any {
    console.log('CalculateAge', { dateNaissance, dateCalcul });
    let age = 0;
    if (dateNaissance) {
      var timeDiff = Math.abs(dateCalcul - dateNaissance);
      //Used Math.floor instead of Math.ceil
      //so 26 years and 140 days would be considered as 26, not 27.
      age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    }
    return age;
  }
  public verifieCoherenceInscriptions() {
    console.log('verifieCoherenceInscriptions');
    this.isAuMoinsUneInscription();
    if (this.selectedEleve) {
      const idEleve = this.selectedEleve['id'];
      console.log('verifieCoherenceInscriptions', this.selectedEleve, this.reinscriptionsDeEleve(idEleve), this.famille.inscriptions);
      // Verifier (sur l'eleve courant) que :
      // s'il est inscrit en FI, t qu'il a moins de 16 ans au 01/09/AAAA alors il doit être inscrit en FM
      //this.estEleveCourantFISansFM
      // Calcul age élève au 01/09/AAAA
      const dateStr = this.selectedEleve['dateNaissStr'];
      const dateNaissance = new Date(dateStr);

      const dateCalcul = new Date();
      dateCalcul.setDate(1);
      dateCalcul.setMonth(8); // Mois septembre (9 - 1)
      const age = this.CalculateAge(dateNaissance, dateCalcul);
      // dateCalcul.setFullYear(this.famille.);
      console.log({ dateNaissance, age });
      if (age < 16) {
        // il faut récupérer type discipline id pour savoir s'il y a une inscription en FI (2) et une en FM (1)
        let isInscritFM = false;
        let isInscritFI = false;
        this.inscriptionsDeEleve(idEleve).forEach(inscription => {
          const ins = <{ isReinscriptionDemandee }>inscription;
          console.log({ inscription, type_discipline_id: +inscription['type_discipline_id'], isAttPresent: inscription.hasOwnProperty('type_discipline_id'), isReinscriptionDemandee: inscription['isReinscriptionDemandee'], isReinscriptionDemandeeBis: ins.isReinscriptionDemandee });
          if (inscription['id_inscription_an_suiv'] != null || inscription['isReinscriptionDemandee'] == true) {
            switch (+inscription['type_discipline_id']) {
              case 1: // FM
                isInscritFM = true;
                break;
              case 2: // FI
                isInscritFI = true;
                break;
            }
          }

        });
        console.log({ isInscritFI, isInscritFM });
        // est-il inscrit en FI ?
        this.estEleveCourantFISansFM = (isInscritFI && !isInscritFM);
      } else {
        this.estEleveCourantFISansFM = false;
      }
    }
  }
  public saveReinscription() {
    /*console.log('saveReinscription',
      { selectedInscription: this.selectedInscription,
        formCours: this.formChoixCours,
        formCoursId: this.formChoixCours.value.id,
        listeCours: this.listeCours,
        idPremierCours: this.listeCours[0].id,
        egalite: (this.formChoixCours.value.id==this.listeCours[0].id) });*/
    const coursSelectionneParUtilisateur = this.listeCours.find(cours => { return cours.id == this.formChoixCours.value.id });
    console.log('cours selectionne par la famille : ', coursSelectionneParUtilisateur);
    const indexInscription = this.formChoixCours.value.indexInscription; // index de l'inscription dans this.famille.inscriptions
    if (coursSelectionneParUtilisateur == null) {
      console.log('aucune sélection');
      /*const inscriptionAGenerer = {
        // isReinscriptionImpossible: true, NON la réinscription n'est pas impossible, c'est juste que l'utilisateur n'a pas sélectionné de cours
        idInscription: this.selectedInscription['idInscription'],
        isReinscriptionDemandee: true,
      }
      this.ajouteReInscription(inscriptionAGenerer);
      this.famille.inscriptions[indexInscription]['isReinscriptionImpossible'] = true;
      */
    } else {
      console.log('sélection ok', coursSelectionneParUtilisateur);
      const inscriptionAGenerer = {
        isReinscriptionDemandee: true,
        idInscription: this.selectedInscription['idInscription'],
        idCoursCible: coursSelectionneParUtilisateur.id,
        libelleCours: this.libelleCours(coursSelectionneParUtilisateur),
      }
      this.famille.inscriptions[indexInscription]['libelleCours'] = this.libelleCours(coursSelectionneParUtilisateur);
      this.famille.inscriptions[indexInscription]['isReinscriptionDemandee'] = true;
      this.famille.inscriptions[indexInscription]['idInscription'] = this.selectedInscription['idInscription'];
      this.famille.inscriptions[indexInscription]['idCoursCible'] = coursSelectionneParUtilisateur.id;
      this.ajouteReInscription(inscriptionAGenerer);
      this.famille.inscriptions = [... this.famille.inscriptions]; // Pour rafraichir l'affichage du tableau
      console.log(this.famille.inscriptions);
    }
    this.ngxSmartModalService.getModal('editChoixCours').close();
  }

  reinscriptionsDeEleve(idEleve) {
    // console.log(this.famille.inscriptions); (+ins['idEleve'] === +idEleve)
    return this.famille.inscriptions.filter(inscription =>
      inscription['idEleve'] == inscription
      && (inscription['id_inscription_an_suiv'] != null || inscription['isReinscriptionDemandee'] == true)
    );
  }
  inscriptionsDeEleve(idEleve) {
    console.log({ ou: 'inscriptionsDeEleve', inscriptions: this.famille.inscriptions, idEleve });
    return this.famille.inscriptions.filter(ins => ins['idEleve'] == idEleve);
  }
  addInscription(idEleve) {
    console.log('addInscription', idEleve, this.selectedEleve['id']);
    const optionsDisciplines = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'selectDisciplines')
        .set('params', JSON.stringify([]))
    };
    const optionsTypesDisciplines = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'getListeTypeDiscipline')
        .set('params', JSON.stringify([]))
    };
    const eleveEnCours = this.famille.eleves.find(eleve => { return eleve['id'] == idEleve });
    console.log({ eleveEnCours, idEleve, eleves: this.famille.eleves });

    forkJoin(
      [this.http.get(api, optionsTypesDisciplines),
      this.http.get(api, optionsDisciplines)]
    ).subscribe(data => {
      const [listeTypesDisciplines, listeDisciplines] = data;
      this.listeTypeDisciplines = <any[]>listeTypesDisciplines;
      this.listeDisciplines = <any[]>listeDisciplines;
      this.listeDisciplines.unshift({ id: null, libelle: '' });
      console.log(data);
      this.formInscription = this.formBuilder.group({
        id: [null,],
        type_discipline_id: 1,
        idDiscipline: [null, Validators.required,],
        idEleve: [idEleve],
        isCreation: true,
        idCreation: this.nextIdCreation(this.famille.inscriptions),
        discipline_libelle: '',
        isReinscriptionDemandee: true, // Pour afficher dans la liste des inscriptions
        isValidationSimple: false, // indique qu'il n'y a pas de cours à choisir
        libelleEleve: (eleveEnCours === undefined) ? '' : (eleveEnCours['nom'] ? eleveEnCours['nom'] : '') + ' ' + (eleveEnCours['prenom'] ? eleveEnCours['prenom'] : ''),
        messageUtilisateur: '',
        isReinscriptionImpossible: false,
        idCours: [null, Validators.required,],
        idCoursCible: null,
        professeurId: null,
        professeurNom: null,
        professeurPrenom: null,
      });
      this.listeCours = [];
      this.editNouveauCours.open();
    });
  }

  saveInscription() {
    console.log('ajouteInscription', this.formInscription, this, this.famille.inscriptions);
    if (this.formInscription.value.idDiscipline != null) {
      if (this.formInscription.value.isValidationSimple) {
        const discipline = this.listeDisciplines.find(discipline => discipline.id == this.formInscription.value.idDiscipline);
        this.formInscription.controls["discipline_libelle"].patchValue(discipline['libelle']);
        //this.famille.inscriptions.push(this.formInscription.value);
        this.famille.inscriptions = [... this.famille.inscriptions, this.formInscription.value];
        console.log(this.famille.inscriptions);
        this.ajouteReInscription(this.formInscription.value);
        this.editNouveauCours.close();
      } else if (this.formInscription.value.isReinscriptionImpossible) {
        console.log('inscription impossible car pas de cours disponible');
      } else {
        console.log('ce n est pas une validation simple, il faut choisir un cours');
        const discipline = this.listeDisciplines.find(discipline => discipline.id == this.formInscription.value.idDiscipline);
        /**
next_cycle_libelle: "Cycle I"
next_niveau_libelle: "CI - 1ère année"
idCycleNext: "1"
idNiveauNext: null
         */
        this.formInscription.controls['discipline_libelle'].patchValue(discipline.libelle);
        // Recherche du cours dans this.listeCours
        const cours = this.listeCours.find(cours => { return cours.id == this.formInscription.value.idCours });
        console.log('cours choisi', cours);
        // TODO il faut stocker l'id du cours pour le passer au serveur et se raccrocher à cce cours sinon il va créer un nouveau cours
        this.formInscription.controls['professeurId'].patchValue(cours.professeur_id);
        this.formInscription.controls['professeurNom'].patchValue(cours.professeur_nom);
        this.formInscription.controls['professeurPrenom'].patchValue(cours.professeur_prenom);
        this.formInscription.controls['idCoursCible'].patchValue(cours.id);

        /*const inscriptionAAjouter = {
          discipline_libelle: discipline.libelle,
          disciplineId: discipline.id,
          id_inscription_an_suiv: null, // pas encore créé
          idEleve: this.formInscription.value.idEleve,

        };*/
        this.famille.inscriptions = [... this.famille.inscriptions, this.formInscription.value];
        this.ajouteReInscription(this.formInscription.value);
        this.editNouveauCours.close();
        this.listeDisciplinesSelectionnee = [];
      }
    }
  }

  private isAuMoinsUneInscription() {
    console.log('calcul nbInscriptions');
    // Contrôler qu'il y a au moins une inscription : soit id_inscription_an_suiv renseigne soit isReinscriptionDemandee==true
    let nbInscriptions = 0;
    this.famille.inscriptions.forEach(inscription => {
      if (inscription['id_inscription_an_suiv'] != null || inscription['isReinscriptionDemandee'] == true) {
        nbInscriptions++;
      }
    });
    this.nbReinscriptions = nbInscriptions;
    console.log('nbInscriptions', nbInscriptions);
    return nbInscriptions > 0;
  }
  valideInscriptions() {
    if (this.isAuMoinsUneInscription()) {
      this.saveDonneesModifiees();
    }
  }

  onDeleteInscription(rowIndex, row) {
    console.log({ ou: 'onDeleteInscription', rowIndex, row});
    if (row.id_inscription_an_suiv == null) {
      // objet n'existe pas encore en BDD => suppression de l'inscription

      if (row.idInscription == null) {
        // c'était une nouvelle inscription => on enlève de la liste
        console.log("onDeleteInscription objet n'existe pas encore en BDD => suppression de l'inscription", row, this.famille, this.familleModifiee);
        // suppression de la liste this.familleModifiee.inscriptions
        if (this.familleModifiee.inscriptions) {
          this.familleModifiee.inscriptions = this.familleModifiee.inscriptions.filter(inscription => {
            return row['idCreation'] ? inscription['idCreation'] != row['idCreation'] : inscription['idInscription'] != row['idInscription']
          });
        }
        // Suppression de la liste this.famille.inscriptions
        if (this.famille.inscriptions) {
          this.famille.inscriptions = this.famille.inscriptions.filter(inscription => {
            return row['idCreation'] ? inscription['idCreation'] != row['idCreation'] : inscription['idInscription'] != row['idInscription']
          });
        }
      } else {
        console.log('suppression des informations de reinscriptions uniquement en laissant l inscription concernee dans les listes');
        // c'était une réinscription => il faut juste enlever les éléments concernant la réinscription mais garder les éléments de l'ancienne inscription pour pouvoir le réinscrire
        if (this.famille.inscriptions) {
          this.famille.inscriptions = this.famille.inscriptions.map(inscription => {
            if (row['idCreation'] ? inscription['idCreation'] == row['idCreation'] : inscription['idInscription'] == row['idInscription']) {
              delete inscription['isReinscriptionDemandee'];
              delete inscription['libelleCours'];
              delete inscription['isReinscriptionDemandee'];
              //delete inscription['idInscription'];
              delete inscription['idCoursCible'];
            }
            return inscription;
          });
        }
        if (this.familleModifiee.inscriptions) {
          this.familleModifiee.inscriptions = this.familleModifiee.inscriptions.map(inscription => {
            if (row['idCreation'] ? inscription['idCreation'] == row['idCreation'] : inscription['idInscription'] == row['idInscription']) {
              delete inscription['idCoursCible'];
              delete inscription['libelleCours'];
              delete inscription['isReinscriptionDemandee'];
            };
            return inscription;
          });
        }
        this.famille.inscriptions = [... this.famille.inscriptions]; // Pour rafraichir l'affichage du tableau
        console.log({ inscriptions: this.famille.inscriptions, modif: this.familleModifiee });
      }
    } else {
      console.log('onDeleteInscription objet existe en BDD');
      // mettre isDeleted à 1
      row.isDeleted = 1;
      // Mise à jour liste des éléments modifiés
      const indiceInscriptionDejaModifie = this.familleModifiee.inscriptions ? this.familleModifiee.inscriptions.findIndex((value) => value['id_inscription_an_suiv'] == row.id_inscription_an_suiv) : -1;
      if (indiceInscriptionDejaModifie < 0) {
        if (!this.familleModifiee.inscriptions) {
          this.familleModifiee.inscriptions = [];
        }
        this.familleModifiee.inscriptions.push(row);
      } else {
        this.familleModifiee.inscriptions[indiceInscriptionDejaModifie] = row;
      }
      // Lancer l'enregistrement pour libérer l'inscription et pouvoir réinscrire tout de suite
      this.saveDonneesModifiees(false, false);
    }
    console.log('fin onDeleteInscription', { reinscriptions: this.famille.inscriptions, changemente: this.familleModifiee });
    this.verifieCoherenceInscriptions();
  }

  chargeDonneesRendezVous() {
    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'selectTypeRDV') //selectTypeRDV selectRDV
        .set('params', JSON.stringify([]))
    };
    this.http.get(api, options).subscribe(data => {
      console.log(data);
      this.listeTypeRendezVous = <any[]>data;
      let premierTypeRDV = null;
      if (this.listeTypeRendezVous.length > 0) {
        premierTypeRDV = this.listeTypeRendezVous[0];
      }
      console.log({ premierTypeRDV });
      this.formRendezVous = this.formBuilder.group({
        typeRendezVous: [
          premierTypeRDV,
        ],
        consentement: [ false, Validators.required, ],
      });
      //this.resultatForDebug = data;
      if (premierTypeRDV) {
        // Initialiser les RDV
        this.typeRendezVousChanged();
      }
    },
      err => console.log(err));
  }
  typeRendezVousChanged() {
    console.log('typeRendezVousChanged', this.calendarComponent, this.formRendezVous.value.typeRendezVous);
    this.listeRendezVousDisponibles = [];
    if (this.formRendezVous && this.formRendezVous.value && this.formRendezVous.value.typeRendezVous) {
      const idTypeRDV = this.formRendezVous.value.typeRendezVous.id;
      console.log(this.formRendezVous.value.typeRendezVous);
      // TODO Rechercher les creneaux disponibles : selectRDVDisponibles($idTypeRDV, $anneeScolaire)
      const options = {
        params: new HttpParams().set('service', 'ServicePortailFamille')
          .set('method', 'selectRDVDisponibles') //selectTypeRDV selectRDV
          .set('params', JSON.stringify([idTypeRDV]))
      };
      this.http.get(api, options).subscribe(data => {
        console.log(data);
        const listeRdvDispo = <any[]>data;
        this.initListeRdv(listeRdvDispo);
      },
        err => console.log(err)
      );
    }
  }
  initListeRdv(listeRdvDispo: any[]) {
    this.listeRendezVousDisponibles = [];
    listeRdvDispo.forEach((rdv => {
      // console.log(rdv);
      rdv.extendedProps = {
        start: rdv.start,
        end: rdv.end,
        idTypeRDV: this.formRendezVous.value.typeRendezVous.id,
      };
      rdv.title = 'Sélectionner'
      this.listeRendezVousDisponibles.push(rdv);
      //{ id: 1, title: 'Sélectionner', start: '2019-05-10 10:00', end: '2019-05-10 10:15' },
    }));
  }
  // Rendez-vous
  eventClick(event) {
    this.rdvSelectionne = event.event;
    console.log({ event: event.event, title: event.event.title, start: event.event.start, rdv: event.extendedProps, id: event.id });
    let m = toMoment(event.event.start, this.calendarComponent.getApi());
    let fin = toMoment(event.event.end, this.calendarComponent.getApi());
    this.libelleRendezVous = "Rendez-vous le " + m.format("DD/MM/YYYY") + " de " + m.format("HH:mm") + " à " + fin.format("HH:mm");
    // alert('Vous avez sélectionné le rendez-vous du ' + m.format("DD/MM/YYYY HH:mm") + ". Le rendez-vous se terminera à " + fin.format("HH:mm"));
    // Test la disponibilité du rendez-vous
    const options = {
      params: new HttpParams().set('service', 'ServicePortailFamille')
        .set('method', 'estRDVDispo')
        .set('params', JSON.stringify([this.rdvSelectionne.extendedProps, false, this.famille['id'], localStorage.getItem('anneeScolaire')]))
    };
    this.http.get(api, options).subscribe(data => {
      console.log(data);
      if (data['isDispo']) {
        console.log('Le rdv est toujours disponible');
      } else {
        console.log('Le rdv n est plus disponible => recupere la liste des rdv dispos dans rdv_dispo');
        this.messageInfoUtilisateur = "Le rendez-vous sélectionné a été réservé par un autre utilisateur. Veuillez en choisir un autre.";
        this.messageInfo.open();
        const listeRdvDispo = <any[]>data['rdv_dispo'];
        this.initListeRdv(listeRdvDispo);
      }
    },
      err => console.log(err));
  }

  saveRendezVous() {
    console.log('saveRendezVous');
    if (this.rdvSelectionne) {
      console.log(this.rdvSelectionne.extendedProps);
      const options = {
        params: new HttpParams().set('service', 'ServicePortailFamille')
          .set('method', 'estRDVDispo')
          .set('params', JSON.stringify([this.rdvSelectionne.extendedProps, true, this.famille['id'], localStorage.getItem('anneeScolaire')]))
      };
      this.http.get(api, options).subscribe(data => {
        console.log(data);
        if (data['isValide']) {
          console.log('Le rdv est valide');
          this.initEtape(true);
          // TODO passer à la dernière étape qui affiche message de confirmation et clos la session 5 minutes plus tard
          this.saveDonneesModifiees(true); // Pour valider les inscriptions
        } else if (!data['isDispo']) {
          console.log('Le rdv n est plus disponible => recupere la liste des rdv dispos dans rdv_dispo');
          this.messageInfoUtilisateur = "Le rendez-vous sélectionné a été réservé par un autre utilisateur. Veuillez en choisir un autre.";
          this.messageInfo.open();
          const listeRdvDispo = <any[]>data['rdv_dispo'];
          this.initListeRdv(listeRdvDispo);
        }
      },
        err => console.log(err));
    }
  }
}
